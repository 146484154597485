const toast = [{
    "name": "Plain Bagel",
    "price": 0
},
{
    "name": "Sesame Bagel",
    "price": 0
},
{
    "name": "Poppy Bagel",
    "price": 0
},
{
    "name": "Everything Bagel",
    "price": 0
},
{
    "name": "Multigrain Bagel",
    "price": 0
},
{
    "name": "Plain Pumpernickel Bagel",
    "price": 0
},
{
    "name": "Sesame Pumpernickel Bagel",
    "price": 0
},
{
    "name": "Cinnamon-Raisin Bagel",
    "price": 0
},
{
    "name": "Cheese Bagel (+$0.99)",
    "price": 0.99
},
{
    "name": "Cheese-Jalapeño Bagel (+$0.99)",
    "price": 0.99
},
{
    "name": "Sesame Twister Bagel (+$0.99)",
    "price": 0.99
},
{
    "name": "Poppy Twister Bagel (+$0.99)",
    "price": 0.99
},
{
    "name": "Flat Sesame Bagel (+$0.99)",
    "price": 0.99
},
{
    "name": "Flat Poppy Bagel (+$0.99)",
    "price": 0.99
},
{
    "name": "Flat Everything Bagel (+$0.99)",
    "price": 0.99
},
{
    "name": "Flat Pumpernickel Sesame Bagel (+$0.99)",
    "price": 0.99
},
{
    "name": "Flat Multigrain Bagel (+$0.99)",
    "price": 0.99
},
{
    "name": "White Bread",
    "price": 0
},
{
    "name": "Whole Wheat (Brown) Bread",
    "price": 0
},
{
    "name": "Rye Bread",
    "price": 0
},
{
    "name": "Challah Bread",
    "price": 0
},
{
    "name": "English Muffin",
    "price": 0
},
{
    "name": "Gluten-Free Plain Bagel (+$1.75)",
    "price": 1.75
},
{
    "name": "Gluten-Free Sesame Bagel (+$1.75)",
    "price": 1.75
},
{
    "name": "Gluten-Free Everything Bagel (+$1.75)",
    "price": 1.75
},
];

const pancakeTopping = [
{
    "name": "No Topping",
    "price": 0
},
{
    "name": "Banana, Walnut and Caramel Sauce (+$4.49)",
    "price": 4.49
},
{
    "name": "Caramelized Apple and Cinnamon (+$4.49)",
    "price": 4.49
},
{
    "name": "Strawberry Compote (+$4.49)",
    "price": 4.49
},
{
    "name": "Fresh Fruit (+$4.49)",
    "price": 4.49
},
{
    "name": "Mixed Berry and Whipped Cream (+$5.49)",
    "price": 5.49
},
{
    "name": "Strawberry, Banana and Nutella (+$5.49)",
    "price": 5.49
},
{
    "name": "Bacon and Real Maple Syrup (+$6.49)",
    "price": 6.49
}];

const breakfastSides = [{
    "name": "Home Fries",
    "price": 0
},
{
    "name": "House Salad",
    "price": 0
},
{
    "name": "French Fries",
    "price": 0
},
{
    "name": "Sweet Potato Fries (+$1.99)",
    "price": 1.99
},
{
    "name": "Poutine Fries (+$4.99)",
    "price": 4.99
},
{
    "name": "Fruit Cup (+$3.99)",
    "price": 3.99
},
{
    "name": "Soup Bowl (+$3.99)",
    "price": 3.99
},
{
    "name": "Medium Caesar Salad(+$4.99)",
    "price": 4.99
},
{
    "name": "Medium Greek Salad (+$5.99)",
    "price": 5.99
},
{
    "name": "Medium Mediterranean Salad (+$5.99)",
    "price": 5.99
},
{
    "name": "Medium Spinach Salad (+$5.99)",
    "price": 5.99
},
{
    "name": "No Side",
    "price": 0
}
];

const sides = [{
    "name": "French Fries",
    "price": 0
},
{
    "name": "House Salad",
    "price": 0
},
{
    "name": "Home Fries (+$1.75)",
    "price": 1.75
},
{
    "name": "Sweet Potato Fries (+$1.99)",
    "price": 1.99
},
{
    "name": "Poutine Fries (+$4.99)",
    "price": 4.99
},
{
    "name": "Fruit Cup (+$3.99)",
    "price": 3.99
},
{
    "name": "Soup Bowl (+$3.99)",
    "price": 3.99
},
{
    "name": "Medium Caesar Salad(+$4.99)",
    "price": 4.99
},
{
    "name": "Medium Greek Salad (+$5.99)",
    "price": 5.99
},
{
    "name": "Medium Mediterranean Salad (+$5.99)",
    "price": 5.99
},
{
    "name": "Medium Spinach Salad (+$5.99)",
    "price": 5.99
},
{
    "name": "No Side",
    "price": 0
}
];

export const beverageData = {
    sectionName: "Beverages",
    entries: [
        {
            "name": "Fresh Squeezed Orange or Grapefruit Juice",
            "price": 6.49
        },
        {
            "name": "Oasis Orange Juice",
            "price": 3.25
        },
        {
            "name": "Bottled Juice",
            "price": 2.49
        },
        {
            "name": "2% Milk (Small)",
            "price": 2.49
        },
        {
            "name": "Chocolate Milk (Small)",
            "price": 2.49
        },
        {
            "name": "2% Milk",
            "price": 3.99
        },
        {
            "name": "Chocolate Milk (Large)",
            "price": 3.99
        },
        {
            "name": "Canned Pop or Bottled Water",
            "price": 2.49
        },
        {
            "name": "Specialty Drinks",
            "price": 3.25
        },
        {
            "name": "Iced Coffee",
            "price": 3.49
        },
        {
            "name": "Coffee",
            "price": 2.75,
            "mealAddOn": [
                {
                    "label": "Add Vanilla Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Hazelnut Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Caramel Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Irish Cream Shot",
                    "price": 1.25
                }
            ]
        },
        {
            "name": "Tea",
            "price": 2.75
        },
        {
            "name": "Hot Chocolate",
            "price": 3.99
        },
        {
            "name": "Espresso",
            "price": 2.49,
            "mealAddOn": [
                {
                    "label": "Add Vanilla Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Hazelnut Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Caramel Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Irish Cream Shot",
                    "price": 1.25
                }
            ]
        },
        {
            "name": "Espresso Machiato",
            "price": 2.99,
            "mealAddOn": [
                {
                    "label": "Add Vanilla Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Hazelnut Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Caramel Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Irish Cream Shot",
                    "price": 1.25
                }
            ]
        },
        {
            "name": "Cappuccino",
            "add": "For a Vanilla, Hazelnut, Irish Cream or Caramel Shot add $0.99",
            "price": 4.49,
            "mealAddOn": [
                {
                    "label": "Add Vanilla Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Hazelnut Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Caramel Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Irish Cream Shot",
                    "price": 1.25
                }
            ]
        },
        {
            "name": "Latte",
            "add": "For a Vanilla, Hazelnut, Irish Cream or Caramel Shot add $0.99",
            "price": 4.49,
            "mealAddOn": [
                {
                    "label": "Add Vanilla Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Hazelnut Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Caramel Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Irish Cream Shot",
                    "price": 1.25
                }
            ]
        },
        {
            "name": "Americano",
            "price": 2.99,
            "mealAddOn": [
                {
                    "label": "Add Vanilla Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Hazelnut Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Caramel Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Irish Cream Shot",
                    "price": 1.25
                }
            ]
        },
        {
            "name": "Americano Red Eye",
            "price": 3.75,
            "mealAddOn": [
                {
                    "label": "Add Vanilla Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Hazelnut Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Caramel Shot",
                    "price": 1.25
                },
                {
                    "label": "Add Irish Cream Shot",
                    "price": 1.25
                }
            ]
        }
    ]
};
export const pancakesData = {
    sectionName: "Pancakes French Toast & Crepes",
    sectionAdd: [{
        "name": "Whipped Cream",
        "price": 2.49
    }],
    entries: [{
        "name": "ButterMilk Pancakes",
        "price": 11.99,
        "proteinChoice": pancakeTopping
    },
    {
        "name": "Sprinkle Pancakes",
        "price": 12.49,
        "proteinChoice": pancakeTopping
    },
    {
        "name": "Chocolate Chip Pancakes",
        "price": 12.99,
        "proteinChoice": pancakeTopping
    },
    {
        "name": "Blueberry Pancakes",
        "price": 12.99,
        "proteinChoice": pancakeTopping
    },
    {
        "name": "Sweet Cheese Pancakes",
        "price": 12.99,
        "proteinChoice": pancakeTopping
    },
    {
        "name": "French Toast",
        "price": 10.99,
        "proteinChoice": pancakeTopping
    },
    {
        "name": "Crunchy French Toast",
        "price": 12.49,
        "proteinChoice": pancakeTopping
    }]
};

export const wafflesData = {
    sectionName: "Waffles",
    sectionDesc: "All Waffles served with whipped cream",
    sectionAdd: [{
        "name": "For Gluten-Free Waffle",
        "price": 4.49
    }],
    entries: [{
        "name": "Banana, Walnut and Caramel Sauce Waffle",
        "price": 13.99
    },
    {
        "name": "Caramelized Apple and Cinnamon Waffle",
        "price": 13.99
    },
    {
        "name": "Strawberry Compote Waffle",
        "price": 13.99
    },
    {
        "name": "Fresh Fruit Waffle",
        "price": 13.99
    },
    {
        "name": "Mixed Berries Waffle",
        "price": 14.99
    },
    {
        "name": "Banana and Walnut Waffle",
        "price": 13.49
    },
    {
        "name": "S'More Waffle",
        "price": 12.49
    },
    {
        "name": "Strawberry, Banana and Nutella Waffle",
        "price": 14.99
    },
    {
        "name": "Bacon and Real Maple Syrup Waffle",
        "price": 15.99
    },
    {
        "name": "Chicken Strips and Chiptole Mayo Waffle",
        "price": 15.99
    }]
};

export const kzData = {
    sectionName: "Kid's Zone",
    sectionDesc: "Kids 10 & Under <br/>Served with Choice of Juice/Milk",
    sectionToast: toast,
    sectionAdd: [{
        "name": "Add One Strip of Bacon",
        "price": 1.49
    },
    {
        "name": "Add One Sausage",
        "price": 1.49
    }],
    entries: [{
        "name": "Kid's Breakfast",
        "description": "Includes One Extra large egg, any style, home fries, 1 slice of bacon, toast or mini bagel",
        "proteinChoice": [{
            "name": "Bacon",
            "price": 0
        },
        {
            "name": "Crispy Bacon",
            "price": 0
        },
        {
            "name": "Sausage",
            "price": 0
        }],
        "price": 6.99
    },
    {
        "name": "Kid's Macaroni and Cheese",
        "price": 7.99,
        "noBread": true,
        "noToasting": true
    },
    {
        "name": "Kid's Grilled Cheese and Fries",
        "price": 6.75,
        "noBread": true,
        "noToasting": true
    },
    {
        "name": "Kid's Chicken Fingers and Fries",
        "price": 9.99,
        "noBread": true,
        "noToasting": true
    },
    {
        "name": "Kid's Pancake",
        "price": 6.99,
        "noBread": true,
        "noToasting": true
    },
    {
        "name": "Kid's Chocolate Chip Pancake",
        "price": 7.25,
        "noBread": true,
        "noToasting": true
    },
    {
        "name": "Kid's Sprinkle Pancake",
        "price": 7.25,
        "noBread": true,
        "noToasting": true
    },
    {
        "name": "Kid's French Toast",
        "price": 7.49,
        "noBread": true,
        "noToasting": true
    }]
};

export const eggData = {
    sectionName: "Eggs Any Style",
    sectionDesc: "All Eggs are served with a choice of Bagel or Toast, House Salad or Home Fries (Except for 4X2 Breakfast)",
    eggServing: [{
        "name": "Scrambled"
    },
    {
        "name": "Scrambled Loose"
    },
    {
        "name": "Over Easy"
    },
    {
        "name": "Over Medium "
    },
    {
        "name": "Over Hard"
    },
    {
        "name": "Sunny Side Up"
    },
    {
        "name": "Omelette Style"
    },
    {
        "name": "Poached Soft"
    },
    {
        "name": "Poached Medium"
    },
    {
        "name": "Poached Hard"
    },
    {
        "name": "No Egg"
    }
    ],
    sectionToast: toast,
    sectionOption: breakfastSides,
    sectionAdd: [{
        "name": "Egg Whites",
        "price": 1.50
    }],
    entries: [{
        "name": "2 Extra Large Eggs Any Style",
        "price": 8.99
    },
    {
        "name": "2 Extra Large Eggs Any Style With Meat",
        "description": "Served with Bacon or Sausage",
        "price": 10.49,
        "proteinChoice": [{
            "name": "Bacon",
            "price": 0
        },
        {
            "name": "Crispy Bacon",
            "price": 0
        },
        {
            "name": "Sausage",
            "price": 0
        },
        {
            "name": "Butterfly Sausage",
            "price": 0
        },
        {
            "name": "Ham (+$2.25)",
            "price": 2.25
        },
        {
            "name": "Pemeal (+$3.25)",
            "price": 3.25
        },
        {
            "name": "Turkey Bacon (+$1.99)",
            "price": 1.99
        }]
    },
    {
        "name": "Great Canadian Breakfast",
        "description": "3 Extra Large Eggs Any Style, 2 Strips of Bacon, 2 Sausages, 2 Slices of Ham, 1 Pancake/French Toast (Limit of 2 pieces of Ham)",
        "proteins": [
            {
                "name": "Bacon",
                "styles": ["Crispy Bacon"]
            },
            {
                "name": "Sausage",
                "styles": ["Butterfly Sausage"]
            },
            {
                "name": "Ham"
            }
        ],
        "proteinChoice": [{
            "name": "Pancake",
            "price": 0
        },
        {
            "name": "French Toast",
            "price": 0
        }],
        "proteinLimit": 6,
        "price": 16.99
    },
    {
        "name": "4X2 Breakfast",
        "description": "2 XL Eggs, 2 Strips of Bacon, 2 Sausages, 2 Pancakes",
        "price": 13.99,
        "proteins": [
            {
                "name": "Bacon",
                "styles": ["Crispy Bacon"]
            },
            {
                "name": "Sausage",
                "styles": ["Butterfly Sausage"]
            }
        ],
        "proteinChoice": [{
            "name": "Pancake",
            "price": 0
        },
        {
            "name": "French Toast",
            "price": 0
        }],
        "proteinLimit": 4,
        "noside": true,
        "noBread": true,
        "noToasting": true
    },
    {
        "name": "Steak and Eggs",
        "description": "2 Extra Large Eggs with a 6 oz. New York Steak",
        "isSteak": true,
        "price": 19.99
    },
    {
        "name": "Shakshuka",
        "description": "Seasoned Tomato Sauce with Vegetables",
        "price": 14.99,
        "mealAddOn": [
            {
                "label": "Add Feta",
                "price": 2.49
            },
            {
                "label": "Add Goat Cheese",
                "price": 2.49
            }
        ]
    },
    {
        "name": "Great Healthy Breakfast",
        "description": "Egg Whites, Avocado, White Albacore Tuna Scoop, Yogurt Parafait",
        "price": 18.45
    }]
};

export const benedictData = {
    sectionName: "Eggs Benedict",
    sectionDesc: "All Eggs Benedict dishes are served on an English Muffin and come with a House Salad or Home Fries",
    sectionToast: [
        {
            "name": "English Muffin",
            "price": 0
        }, {
            "name": "Plain Bagel",
            "price": 0
        },
        {
            "name": "Sesame Bagel",
            "price": 0
        },
        {
            "name": "Poppy Bagel",
            "price": 0
        },
        {
            "name": "Everything Bagel",
            "price": 0
        },
        {
            "name": "Multigrain Bagel",
            "price": 0
        },
        {
            "name": "Plain Pumpernickel Bagel",
            "price": 0
        },
        {
            "name": "Sesame Pumpernickel Bagel",
            "price": 0
        },
        {
            "name": "Cinnamon-Raisin Bagel",
            "price": 0
        },
        {
            "name": "Cheese Bagel (+$0.99)",
            "price": 0.99
        },
        {
            "name": "Cheese-Jalapeño Bagel (+$0.99)",
            "price": 0.99
        },
        {
            "name": "Sesame Twister Bagel (+$0.99)",
            "price": 0.99
        },
        {
            "name": "Poppy Twister Bagel (+$0.99)",
            "price": 0.99
        },
        {
            "name": "Flat Sesame Bagel (+$0.99)",
            "price": 0.99
        },
        {
            "name": "Flat Poppy Bagel (+$0.99)",
            "price": 0.99
        },
        {
            "name": "Flat Everything Bagel (+$0.99)",
            "price": 0.99
        },
        {
            "name": "Flat Pumpernickel Sesame Bagel (+$0.99)",
            "price": 0.99
        },
        {
            "name": "Flat Multigrain Bagel (+$0.99)",
            "price": 0.99
        },
        {
            "name": "White Bread",
            "price": 0
        },
        {
            "name": "Whole Wheat (Brown) Bread",
            "price": 0
        },
        {
            "name": "Rye Bread",
            "price": 0
        },
        {
            "name": "Challah Bread",
            "price": 0
        },
        {
            "name": "Gluten-Free Plain Bagel (+$1.75)",
            "price": 1.75
        },
        {
            "name": "Gluten-Free Sesame Bagel (+$1.75)",
            "price": 1.75
        },
        {
            "name": "Gluten-Free Everything Bagel (+$1.75)",
            "price": 1.75
        },
    ],
    sectionOption: breakfastSides,
    eggServing: [{
        "name": "Poached Soft"
    },
    {
        "name": "Poached Medium"
    },
    {
        "name": "Poached Hard"
    }],
    entries: [{
        "name": "Eggs Benedict Peameal Bacon",
        "price": 14.49,
        "noToasting": true
    },
    {
        "name": "Eggs Benedict Smoked Salmon",
        "price": 15.99,
        "noToasting": true
    },
    {
        "name": "Eggs Benedict on a 6oz New York Steak",
        "price": 19.99,
        "isSteak": true,
        "noToasting": true
    },
    {
        "name": "Veggie Benedict",
        "description": "Grilled Tomato, Avocado, and Goat Cheese",
        "price": 14.99,
        "noToasting": true
    },
    {
        "name": "Eggs Benedict Florentine",
        "description": "Add Feta 2.99",
        "price": 13.49,
        "noToasting": true,
        "mealAddOn": [
            {
                "label": "Add Feta",
                "price": 2.99
            }
        ],
    },
    {
        "name": "Eggs Benedict On a Savoury Waffle with Peameal Bacon",
        "price": 16.49,
        "noBread": true,
        "noToasting": true
    },
    {
        "name": "Philly Cheesesteak Benedict",
        "price": 16.99,
        "noToasting": true
    }]
};

export const omeletteData = {
    sectionName: "3 Egg Omelettes",
    sectionDesc: "All omelettes are served with a choice of Bagel or Toast, House Salad or Home Fries",
    sectionToast: toast,
    sectionOption: breakfastSides,
    sectionAdd: [{
        "name": "Egg Whites",
        "price": 1.50
    }],
    entries: [{
        "name": "Create Your Own Omelette",
        "description": "SELECT UP TO THREE ITEMS: Cheddar, Mozzarella, Goat Cheese, Feta, Mushrooms, Green Onions, Red Peppers, Red Onions, Tomatoes, Spinach, Green Peppers, Smoked Salmon, Carmelized Onions, Broccoli, Bacon, Ham or Sausage",
        "add": "Additional Toppings Add $1.25 Each",
        "price": 15.99,
        "extraAllowed": true,
        "proteins": [
            {
                "name": "Cheddar"
            },
            {
                "name": "Mozzarella"
            },
            {
                "name": "Feta"
            },
            {
                "name": "Goat Cheese"
            },
            {
                "name": "Mushrooms"
            },
            {
                "name": "Green Onion"
            },
            {
                "name": "Red Peppers"
            },
            {
                "name": "Red Onions"
            },
            {
                "name": "Tomatoes"
            },
            {
                "name": "Spinach"
            },
            {
                "name": "Green Peppers"
            },
            {
                "name": "Broccoli"
            },
            {
                "name": "Caramelized Onion"
            },
            {
                "name": "Smoked Salmon"
            },
            {
                "name": "Bacon"
            },
            {
                "name": "Ham"
            },
            {
                "name": "Sausage"
            }
        ],
        "proteinLimit": 3
    },
    {
        "name": "Spanish Omelette",
        "description": "Bell Peppers, Onions, Mushrooms, and Spanish Sauce",
        "price": 14.25
    },
    {
        "name": "Cheddar Cheese Omelette",
        "price": 13.99
    },
    {
        "name": "Mushroom Omelette",
        "price": 13.99
    },
    {
        "name": "Western Omelette",
        "description": "Smoked Ham, Bell Peppers, Onions, Mushrooms, and Tomatoes",
        "price": 14.99
    },
    {
        "name": "Mushroom and Swiss Cheese Omelette",
        "price": 14.99
    },
    {
        "name": "Greek Omelette",
        "description": "Bell Peppers, Tomatoes and Feta Cheese",
        "price": 14.99
    },
    {
        "name": "Spinach and Feta Omelette",
        "price": 14.99
    },
    {
        "name": "Peameal Bacon, Mushroom and Cheddar Omelette",
        "price": 15.49
    },
    {
        "name": "Eggs and Caramelized Onions Omelette",
        "description": "Scrambled or Omelette Style",
        "add": "Add Lox 3.49",
        "price": 13.49,
        "mealAddOn": [
            {
                "label": "Add Lox (+$3.49)",
                "price": 3.49
            }
        ]
    },
    {
        "name": "Meat Lovers Omelette",
        "description": "Ham, Bacon and Sausage",
        "price": 14.99
    },
    {
        "name": "Caramelized Onions, Sausage and Cheddar Omelette",
        "price": 14.99
    },
    {
        "name": "Grilled Vegetable and Goat Cheese Omelette",
        "price": 14.99
    },
    {
        "name": "Tex-Mex Omelette",
        "description": "Avocado, Onions, Jalapeño Peppers, Tomato Salsa, Cheddar Cheese and Sour Cream",
        "price": 14.99
    }]
};

export const otherData = {
    sectionName: "Other Options",
    entries: [{
        "name": "Oatmeal",
        "price": 7.49,
        "isOatmeal": true
    },
    {
        "name": "Oatmeal with Blueberries",
        "price": 8.99,
        "isOatmeal": true
    },
    {
        "name": "Fruit Cup with Yogurt",
        "description": "Served with 3oz of Low Fat Yogurt",
        "price": 9.98
    },
    {
        "name": "Seasonal Fresh Fruit Cup",
        "price": 7.99
    },
    {
        "name": "Low Fat Yogurt Parfait",
        "description": "Granola, Honey, and Fruit",
        "price": 9.99
    },
    {
        "name": "Mac & Cheese",
        "add": "Add Bacon 2.99",
        "price": 11.99,
        "mealAddOn": [
            {
                "label": "Add Bacon",
                "price": 1.49
            }
        ],
    },
    {
        "name": "Chicken Fingers & Fries",
        "price": 13.99
    }]
};

export const platterData = {
    sectionName: "Platters & Sandwiches",
    sectionToast: toast,
    sectionDesc: "All Platters and Sandwiches are served with your choice of Bagel or Bread and your choice of Vegetable Slices or House Salad.",
    sectionAdd: [{
        "name": "Substitute Salad or Veggies for French Fries",
        "price": 2.25
    },
    {
        "name": "Add French Fries",
        "price": 3.99
    },
    {
        "name": "Sweet Fries",
        "price": 4.99
    }
    ],
    entries: [{
        "name": "Tuna Salad",
        "price": 11.99
    },
    {
        "name": "Large Tuna Salad",
        "price": 12.99
    },
    {
        "name": "Chicken Salad",
        "price": 11.99
    },
    {
        "name": "Large Chicken Salad",
        "price": 12.99
    },
    {
        "name": "Chopped Egg Salad",
        "price": 8.99
    },
    {
        "name": "Large Chopped Egg Salad",
        "price": 10.49
    },
    {
        "name": "Cream Cheese and Lox",
        "price": 17.49
    },
    {
        "name": "Combo Platter",
        "description": "Chopped Egg, Cream Cheese and Tuna, includes 2 Bagels",
        "secondToast": true,
        "price": 22.99

    },
    {
        "name": "Cream Cheese",
        "price": 7.99
    },
    {
        "name": "Large Cream Cheese",
        "price": 9.49
    },
    {
        "name": "Flavoured Cream Cheese",
        "proteinChoice": [{
            "name": "Chives Cream Cheese",
            "price": 0
        },
        {
            "name": "Dill Pickle & Garlic Cream Cheese",
            "price": 0
        },
        {
            "name": "Feta & Spinach Cream Cheese",
            "price": 0
        },
        {
            "name": "Cheddar & Jalapeño Cream Cheese",
            "price": 0
        }
        ],
        "price": 8.49
    },
    {
        "name": "Large Flavoured Cream Cheese",
        "proteinChoice": [{
            "name": "Chives Cream Cheese",
            "price": 0
        },
        {
            "name": "Dill Pickle & Garlic Cream Cheese",
            "price": 0
        },
        {
            "name": "Feta & Spinach Cream Cheese",
            "price": 0
        },
        {
            "name": "Cheddar & Jalapeño Cream Cheese",
            "price": 0
        }
        ],
        "price": 9.99
    }]
};

export const soupData = {
    sectionName: "Soup",
    sectionToast: toast,
    entries: [{
        "name": "Homemade Soup",
        "price": 6.49,
        "noBread": true,
        "noToasting": true
    },
    {
        "name": "Soup and a Bagel Combo",
        "description": "",
        "price": 7.99
    },
    {
        "name": "Soup and a Sandwich Combo",
        "description": "Choice of Chopped Egg or Tuna And Comes With Lettuce & Tomato",
        "price": 12.99,
        "proteinChoice": [{
            "name": "Tuna Salad",
            "price": 0
        },
        {
            "name": "Egg Salad",
            "price": 0
        }],
    }]
};

export const sandwhichData = {
    sectionName: "Sandwiches",
    sectionDesc: "All Sandwiches and Wraps are served with French Fries or House Salad",
    sectionOption: sides,
    sectionToast: toast,
    sectionAdd: [
        {
            "name": "Add Avocado",
            "price": 2.99
        }],
    entries: [{
        "name": "Philly Cheese Steak Sandwich",
        "price": 15.99,
        "noBread": true,
        "noToasting": true
    },
    {
        "name": "Philly Cheese Chicken Sandwich",
        "price": 15.99,
        "noBread": true,
        "noToasting": true
    },
    {
        "name": "6oz New York Steak Sandwich",
        "description": "Topped with Sauteed Onions and Mushrooms",
        "price": 18.99,
        "isSteak": true,
        "noBread": true,
        "noToasting": true
    },
    {
        "name": "Western Sandwich",
        "price": 11.99
    },
    {
        "name": "Eggs, Bacon and Cheese Sandwich",
        "price": 11.99
    },
    {
        "name": "BLT (Bacon, Lettuce and Tomato) Sandwich",
        "price": 11.49,
        "mealAddOn": [
            {
                "label": "Add Mayo",
                "price": 0
            }
        ]
    },
    {
        "name": "Triple Decker Club Sandwich",
        "description": "Grilled Chicken or Roasted Turkey Slices, Bacon, Tomato and Lettuce (if chosen on a bagel, then it comes as a regular sandwich)",
        "price": 16.99,
        "noToasting": true,
        "mealAddOn": [
            {
                "label": "Add Mayo",
                "price": 0
            }
        ],
        "proteinChoice": [{
            "name": "Grilled Chicken Breast",
            "price": 0
        },
        {
            "name": "Deli Roasted Turkey Slices",
            "price": 0
        }]
    },
    {
        "name": "Triple Decker Breakfast Club Sandwich",
        "description": "Fried Egg, Peameal Bacon, Tomato and Lettuce  (if chosen on a bagel, then it comes as a regular sandwich",
        "price": 13.99,
        "noToasting": true,
        "mealAddOn": [
            {
                "label": "Add Mayo",
                "price": 0
            }
        ]
    },
    {
        "name": "Grilled Chicken With Pesto and Goat Cheese Sandwich",
        "description": "Pesto and Goat Cheese",
        "price": 15.99,
        "noBread": true,
        "noToasting": true
    },
    {
        "name": "Grilled Chicken With Mushroom and Swiss Cheese Sandwich",
        "description": "Mushroom, BBQ Sauce and Swiss Cheese",
        "price": 16.99,
        "mealAddOn": [
            {
                "label": "No BBQ Sauce",
                "price": 0
            }
        ],
        "noBread": true,
        "noToasting": true
    },
    {
        "name": "Grilled Cheese Sandwich",
        "description": "Only comes on sliced bread",
        "mealAddOn": [
            {
                "label": "Add Bacon",
                "price": 1.49
            }
        ],
        "price": 10.49,
        "noToasting": true
    },
    {
        "name": "Grilled Cheese With Avocado, Tomato and Egg Sandwich",
        "description": "With Avocado, Tomato and Fried Egg on Challah",
        "price": 13.49,
        "noBread": true,
        "noToasting": true

    },
    {
        "name": "Mediterranean Panini Sandwich",
        "description": "Shakshuka Sauce, Tuna, Goat Cheese & Mozzarella on a Challah Bun",
        "price": 14.99,
        "noBread": true,
        "noToasting": true
    },
    {
        "name": "The Vegan Sandwich",
        "description": "Pesto, Avocado, Tomato and Baby Spinach (Only the breads are vegan, bagels contain egg)",
        "price": 10.99
    }]
};

export const wrapData = {
    sectionName: "Wraps",
    sectionOption: sides,
    sectionToast: [{
        "name": "Plain Wrap",
        "price": 0
    },
    {
        "name": "Whole Wheat Wrap",
        "price": 0
    }],
    entries: [{
        "name": "Chicken Caesar Wrap",
        "description": "Grilled Chicken, Romaine, Parmesan, Caesar Dressing",
        "price": 14.99,
        "noToasting": true
    },
    {
        "name": "Tuna Wrap",
        "description": "Mixed Greens, Tomato Cucumber",
        "price": 14.99,
        "noToasting": true
    },
    {
        "name": "Breakfast Wrap",
        "description": "Egg, Spinach, Cheddar, and Mushroom Served with Home Fries",
        "price": 13.49,
        "noToasting": true
    },
    {
        "name": "Buffalo Chicken Wrap",
        "description": "Spicy Cripsy Chicken Tenders",
        "price": 14.99,
        "noToasting": true
    }]
};
export const saladData = {
    sectionName: "Signature Salads",
    sectionAdd: [
        {
            "name": "Add Grilled Chicken Breast",
            "price": 6.99
        },
        {
            "name": "Scoop Chopped Egg",
            "price": 5.49
        },
        {
            "name": "Scoop of White Tuna",
            "price": 6.49
        },
        {
            "name": "Scoop of Chicken Salad",
            "price": 6.49
        }
    ],
    sectionToast: [{
        "name": "Plain Bagel",
        "price": 0
    },
    {
        "name": "Sesame Bagel",
        "price": 0
    },
    {
        "name": "Poppy Bagel",
        "price": 0
    },
    {
        "name": "Everything Bagel",
        "price": 0
    },
    {
        "name": "Multigrain Bagel",
        "price": 0
    },
    {
        "name": "Plain Pumpernickel Bagel",
        "price": 0
    },
    {
        "name": "Sesame Pumpernickel Bagel",
        "price": 0
    },
    {
        "name": "Cinnamon-Raisin Bagel",
        "price": 0
    },
    {
        "name": "Cheese Bagel (+$0.99)",
        "price": 0.99
    },
    {
        "name": "Cheese-Jalapeño Bagel (+$0.99)",
        "price": 0.99
    },
    {
        "name": "Sesame Twister Bagel (+$0.99)",
        "price": 0.99
    },
    {
        "name": "Poppy Twister Bagel (+$0.99)",
        "price": 0.99
    },
    {
        "name": "Flat Sesame Bagel (+$0.99)",
        "price": 0.99
    },
    {
        "name": "Flat Poppy Bagel (+$0.99)",
        "price": 0.99
    },
    {
        "name": "Flat Everything Bagel (+$0.99)",
        "price": 0.99
    },
    {
        "name": "Flat Pumpernickel Sesame Bagel (+$0.99)",
        "price": 0.99
    },
    {
        "name": "Flat Multigrain Bagel (+$0.99)",
        "price": 0.99
    },
    {
        "name": "White Bread",
        "price": 0
    },
    {
        "name": "Whole Wheat Bread",
        "price": 0
    },
    {
        "name": "Rye Bread",
        "price": 0
    },
    {
        "name": "Challah Bread",
        "price": 0
    },
    {
        "name": "English Muffin",
        "price": 0
    }
    ],
    sectionOption: [{
        "name": "Balsamic Dressing",
        "price": 0
    },
    {
        "name": "Ceasar Dressing",
        "price": 0
    },
    {
        "name": "Ranch Dressing",
        "price": 0
    },
    {
        "name": "Raspberry Vinaigrette Dressing",
        "price": 0
    },
    {
        "name": "Greek Dressing",
        "price": 0
    },
    {
        "name": "Olive Oil & Lemon Juice Dressing",
        "price": 0
    },
    {
        "name": "Poppy Seed Dressing",
        "price": 0
    }
    ],
    entries: [{
        "name": "Caesar Salad",
        "description": "Romaine Lettuce, Croutons, Parmesan Cheese",
        "price": 13.99
    },
    {
        "name": "Greek Salad",
        "description": "Iceberg and Romaine Lettuce, Tomatoes, Bell Peppers, Cucumbers, Feta Cheese, Olives and Egg",
        "price": 14.99
    },
    {
        "name": "Goat Cheese Salad",
        "description": "Spring Mix, Pecans, Walnuts, Goat Cheese and Dried Cranberries (Recommended with Raspberry Vinaigrette Dressing)",
        "price": 14.99
    },
    {
        "name": "Cobb Salad",
        "description": "Iceberg and Romaine Lettuce, Feta Cheese, Avocado, Tomatoes, Egg, Roasted Turkey and Bacon",
        "price": 16.99
    },
    {
        "name": "Spinach Salad",
        "description": "Baby Spinach, Spring Mix, Strawberries, Mandarins, Pecans and Walnuts (Recommended with Poppy Seed Dressing)",
        "price": 14.99
    },
    {
        "name": "Mediterranean Salad",
        "description": "Chopped Tomatoes, Cucumbers, Onions, Peppers and Fresh Parsley (Recommended with Olive Oil & Lemon Juice Dressing)",
        "price": 14.99
    },
    {
        "name": "Bagel World Salad",
        "description": "Spring Mix, Tomatoes, Cucumbers, Peppers, Albacore Tuna, Eggs and Feta Cheese",
        "price": 16.99
    }]
};

export const bagelliniData = {
    sectionName: "Bagellinis",
    sectionToast: [{
        "name": "Flat Sesame Bagel",
        "price": 0
    },
    {
        "name": "Flat Poppy Bagel",
        "price": 0
    },
    {
        "name": "Flat Everything Bagel",
        "price": 0
    },
    {
        "name": "Flat Pumpernickel Sesame Bagel",
        "price": 0
    },
    {
        "name": "Flat Multigrain Bagel",
        "price": 0
    }],
    sectionOption: [{
        "name": "French Fries",
        "price": 0
    },
    {
        "name": "House Salad",
        "price": 0
    },
    {
        "name": "Sweet Potato Fries (+$1.99)",
        "price": 1.99
    },
    {
        "name": "Home Fries (+$1.49)",
        "price": 1.49
    },
    {
        "name": "Poutine Fries (+$4.99)",
        "price": 4.99
    },
    ],
    entries: [{
        "name": "Grilled Vegetables with Goat Cheese Bagellini",
        "price": 13.99,
        "noToasting": true
    },
    {
        "name": "Tuna, Cheddar, Tomato and Red Onions Bagellini",
        "price": 13.99,
        "noToasting": true
    },
    {
        "name": "Mozzarella, Tomatoes and Avocado Bagellini",
        "price": 13.49,
        "noToasting": true
    },
    {
        "name": "Hummus, Baby Spinach, Sauteed Mushrooms and Cheddar Bagellini",
        "price": 13.49,
        "noToasting": true
    },
    {
        "name": "Egg, Bacon and Cheese Bagellini",
        "price": 13.99,
        "noToasting": true
    },
    {
        "name": "Pizza Bagellini",
        "description": "Mozzarella, Cheddar, Feta, Tomato Sauce and Bell Peppers",
        "price": 13.99,
        "noToasting": true
    }]
};

export const burgerData = {
    sectionName: "Homemade Burgers",
    sectionAdd: [
        {
            "name": "Add Avocado",
            "price": 2.99
        }],
    sectionOption: [{
        "name": "French Fries",
        "price": 0
    },
    {
        "name": "House Salad",
        "price": 0
    },
    {
        "name": "Sweet Potato Fries (+$1.99)",
        "price": 1.99
    },
    {
        "name": "Home Fries (+$1.49)",
        "price": 1.49
    },
    {
        "name": "Poutine Fries (+$4.99)",
        "price": 4.99
    },
    ],
    entries: [{
        "name": "The Classic Burger",
        "price": 13.99
    },
    {
        "name": "Swiss Mushroom Burger",
        "price": 15.49
    },
    {
        "name": "Cheddar and Bacon Burger",
        "price": 15.49
    },
    {
        "name": "Cheddar and Avocado Burger",
        "price": 15.49
    },
    {
        "name": "The Veggie Burger",
        "price": 12.99
    }]
};

export const sideData = {
    sectionName: "Sides and Additions",
    sectionToast: [{
        "name": "Plain Bagel",
        "price": 0
    },
    {
        "name": "Sesame Bagel",
        "price": 0
    },
    {
        "name": "Poppy Bagel",
        "price": 0
    },
    {
        "name": "Everything Bagel",
        "price": 0
    },
    {
        "name": "Multigrain Bagel",
        "price": 0
    },
    {
        "name": "Plain Pumpernickel Bagel",
        "price": 0
    },
    {
        "name": "Sesame Pumpernickel Bagel",
        "price": 0
    },
    {
        "name": "Cinnamon-Raisin Bagel",
        "price": 0
    },
    {
        "name": "Cheese Bagel (+$0.99)",
        "price": 0.99
    },
    {
        "name": "Cheese-Jalapeño Bagel (+$0.99)",
        "price": 0.99
    },
    {
        "name": "Sesame Twister Bagel (+$0.50)",
        "price": 0.50
    },
    {
        "name": "Poppy Twister Bagel (+$0.50)",
        "price": 0.50
    },
    {
        "name": "Flat Sesame Bagel (+$0.50)",
        "price": 0.50
    },
    {
        "name": "Flat Poppy Bagel (+$0.50)",
        "price": 0.50
    },
    {
        "name": "Flat Everything Bagel (+$0.50)",
        "price": 0.50
    },
    {
        "name": "Flat Pumpernickel Sesame Bagel (+$0.50)",
        "price": 0.50
    },
    {
        "name": "Flat Multigrain Bagel (+$0.50)",
        "price": 0.50
    },
    {
        "name": "White Bread",
        "price": 0
    },
    {
        "name": "Whole Wheat (Brown) Bread",
        "price": 0
    },
    {
        "name": "Rye Bread",
        "price": 0
    },
    {
        "name": "Challah Bread",
        "price": 0
    },
    {
        "name": "English Muffin",
        "price": 0
    },
    {
        "name": "Gluten-Free Plain Bagel (+$1.75)",
        "price": 1.75
    },
    {
        "name": "Gluten-Free Sesame Bagel (+$1.75)",
        "price": 1.75
    },
    {
        "name": "Gluten-Free Everything Bagel (+$1.75)",
        "price": 1.75
    },
    ],
    entries: [{
        "name": "French Fries",
        "noBread": true,
        "noToasting": true,
        "price": 5.99
    },
    {
        "name": "Sweet Potato Fries",
        "description": "Served with Chipotle Mayo",
        "noBread": true,
        "noToasting": true,
        "price": 7.49
    },
    {
        "name": "Home Fries",
        "noBread": true,
        "noToasting": true,
        "price": 6.99
    },
    {
        "name": "Poutine Fries",
        "noBread": true,
        "noToasting": true,
        "price": 8.99
    },
    {
        "name": "Gravy",
        "noBread": true,
        "noToasting": true,
        "price": 1.75
    },
    {
        "name": "One Scoop of Cream Cheese",
        "proteinChoice": [{
            "name": "Plain Cream Cheese",
            "price": 0
        },
        {
            "name": "Chives Cream Cheese",
            "price": 0
        },
        {
            "name": "Dill Pickle & Garlic Cream Cheese",
            "price": 0
        },
        {
            "name": "Feta & Spinach Cream Cheese",
            "price": 0
        },
        {
            "name": "Cheddar & Jalapeño Cream Cheese",
            "price": 0
        }
        ],
        "noBread": true,
        "noToasting": true,
        "price": 5.49
    },
    {
        "name": "One Scoop of Chicken Salad",
        "noBread": true,
        "noToasting": true,
        "price": 6.99
    },
    {
        "name": "One Scoop of Tuna Salad",
        "noBread": true,
        "noToasting": true,
        "price": 6.99
    },
    {
        "name": "One Scoop of Chopped Egg Salad",
        "noBread": true,
        "noToasting": true,
        "price": 5.99
    },
    {
        "name": "One Egg Any Any Style",
        "proteinChoice": [{
            "name": "Scrambled",
            "price": 0
        },
        {
            "name": "Scrambled Loose",
            "price": 0
        },
        {
            "name": "Over Easy",
            "price": 0
        },
        {
            "name": "Over Medium ",
            "price": 0
        },
        {
            "name": "Over Hard",
            "price": 0
        },
        {
            "name": "Sunny Side Up",
            "price": 0
        },
        {
            "name": "Omelette Style",
            "price": 0
        },
        {
            "name": "Poached Soft",
            "price": 0
        },
        {
            "name": "Poached Medium",
            "price": 0
        },
        {
            "name": "Poached Hard",
            "price": 0
        }],
        "noBread": true,
        "noToasting": true,
        "price": 2.45
    },
    {
        "name": "Avocado",
        "noBread": true,
        "noToasting": true,
        "price": 2.99
    },
    {
        "name": "Bacon 3 Strips",
        "noBread": true,
        "noToasting": true,
        "price": 5.49
    },
    {
        "name": "Sausage 3 Links",
        "noBread": true,
        "noToasting": true,
        "price": 5.49
    },
    {
        "name": "Peameal Bacon (2 slices)",
        "noBread": true,
        "noToasting": true,
        "price": 5.45
    },
    {
        "name": "Smoked Salmon (50g)",
        "noBread": true,
        "noToasting": true,
        "price": 7.49
    },
    {
        "name": "Our World Famous Bagel with Butter",
        "price": 2.49
    },
    {
        "name": "Cream Cheese (2oz)",
        "noBread": true,
        "noToasting": true,
        "proteinChoice": [{
            "name": "Plain Cream Cheese",
            "price": 0
        },
        {
            "name": "Chives Cream Cheese",
            "price": 0
        },
        {
            "name": "Dill Pickle & Garlic Cream Cheese",
            "price": 0
        },
        {
            "name": "Feta & Spinach Cream Cheese",
            "price": 0
        },
        {
            "name": "Cheddar & Jalapeño Cream Cheese",
            "price": 0
        }
        ],
        "price": 2.79
    },
    {
        "name": "Side Pancake",
        "noBread": true,
        "noToasting": true,
        "price": 4.49
    },
    {
        "name": "Side Waffle - Plain",
        "noBread": true,
        "noToasting": true,
        "price": 7.99
    }]
};